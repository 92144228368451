import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router'

import * as _ from 'lodash-es'
import { ConfirmationService, TermService, AuthService } from '../../services'
import { ConfirmationModal } from '../../widgets/confirmation-modal'

import 'rxjs/add/operator/filter'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/mergeMap'

export function getCreateUpdatePermission (activatedRoute: ActivatedRouteSnapshot): string {
  return activatedRoute.params.id === 'new' ? 'create' : 'get'
}

export function getScheduleUpdateExamPermission (activatedRoute: ActivatedRouteSnapshot): string {
  return activatedRoute.params.id === 'new' ? 'schedule-exam' : 'get'
}

export function getGradeCreateUpdatePermission (activatedRoute: ActivatedRouteSnapshot): string {
  return activatedRoute.params.id === 'new' ? 'add-grade' : 'get-grade'
}

// @ts-expect-error ts-migrate(6133) FIXME: 'activatedRoute' is declared but its value is neve... Remove this comment to see the full error message
export function usersSearchResource (activatedRoute: ActivatedRouteSnapshot, tenant: any): string {
  return tenant ? 'tenant' : 'user'
}

// @ts-expect-error ts-migrate(6133) FIXME: 'activatedRoute' is declared but its value is neve... Remove this comment to see the full error message
export function usersSearchPermission (activatedRoute: ActivatedRouteSnapshot, tenant: any): string {
  return tenant ? 'get-members' : 'search'
}

// @ts-expect-error ts-migrate(6133) FIXME: 'activatedRoute' is declared but its value is neve... Remove this comment to see the full error message
export function userDetailResource (activatedRoute: ActivatedRouteSnapshot, tenant: any): string {
  return tenant ? 'tenant' : 'user'
}

export function userDetailPermission (activatedRoute: ActivatedRouteSnapshot, tenant: any): string {
  if (!tenant) {
    return activatedRoute.params.id === 'new' ? 'create' : 'get'
  }
  return activatedRoute.params.id === 'new' ? 'add-member' : 'get-member'
}

export const PAGES = {
  profile: {
    path: 'profile',
    data: {
      icon: 'lock',
      title: 'PROFILE.TITLE'
    }
  },
  home: {
    path: 'home',
    sidebar: true,
    data: {
      icon: 'dashboard',
      title: 'HOME.TITLE'
    }
  },
  tenant: {
    path: 'tenants/:id',
    data: {
      icon: 'shield',
      title: 'TENANT.DETAIL_TITLE',
      resource: 'tenant',
      permission: getCreateUpdatePermission
    }
  },
  forcePassword: {
    path: 'users/:id/force-password',
    data: {
      icon: 'key',
      title: 'USER.FORCE_PASSWORD',
      resource: 'tenant',
      permission: 'force-member-password'
    }
  },

  // custom per app
  topics: {
    path: 'topics',
    sidebar: true,
    data: {
      icon: 'newspaper-o',
      title: 'TOPIC.SEARCH_TITLE',
      resource: 'topic',
      permission: 'search',
      requiresTenant: true
    }
  },
  topic: {
    path: 'topics/:id',
    data: {
      icon: 'newspaper-o',
      title: 'TOPIC.DETAIL_TITLE',
      resource: 'topic',
      permission: getCreateUpdatePermission,
      requiresTenant: true
    }
  },
  albums: {
    path: 'albums',
    sidebar: true,
    data: {
      icon: 'picture-o',
      title: 'ALBUM.SEARCH_TITLE',
      resource: 'topic',
      permission: 'search',
      requiresTenant: true
    }
  },
  album: {
    path: 'albums/:id',
    data: {
      icon: 'picture-o',
      title: 'ALBUM.DETAIL_TITLE',
      resource: 'topic',
      permission: getCreateUpdatePermission,
      requiresTenant: true
    }
  },
  feed: {
    path: 'feed/:id',
    data: {
      icon: 'rss',
      title: 'TOPIC.SEARCH_TITLE',
      resource: 'topic',
      permission: 'search',
      requiresTenant: true
    }
  },
  attendance: {
    path: 'attendance/:stageId/:gradeId/:divisionId',
    data: {
      icon: 'check-square-o',
      title: 'DIVISION.ATTENDANCE',
      resource: 'division',
      permission: 'attendance',
      requiresTenant: true
    }
  },
  promotion: {
    path: 'divisions/:id/promote',
    data: {
      icon: 'level-up',
      title: 'PROMOTION.PROMOTE',
      resource: 'division',
      permission: 'promote',
      requiresTenant: true
    }
  },
  exam: {
    path: 'divisions/:divisionId/exams/:id',
    data: {
      icon: 'file-text-o',
      title: 'TOPIC_TYPES.exam',
      resource: 'division',
      permission: getScheduleUpdateExamPermission,
      requiresTenant: true
    }
  },
  rating: {
    path: 'divisions/:divisionId/ratings/:id',
    data: {
      icon: 'file-text-o',
      title: 'TOPIC_TYPES.rating',
      resource: 'division',
      permission: getScheduleUpdateExamPermission,
      requiresTenant: true
    }
  },

  people: {
    header: true,
    sidebar: true,
    data: {
      title: 'MENUASIDE.PEOPLE'
    }
  },
  students: {
    path: 'students',
    sidebar: true,
    data: {
      icon: 'universal-access',
      title: 'STUDENT.SEARCH_TITLE',
      resource: 'student',
      permission: 'search',
      requiresTenant: true
    }
  },
  student: {
    path: 'students/:id',
    data: {
      icon: 'universal-access',
      title: 'STUDENT.DETAIL_TITLE',
      resource: 'student',
      permission: getCreateUpdatePermission,
      requiresTenant: true
    }
  },
  guardians: {
    path: 'guardians',
    sidebar: true,
    data: {
      icon: 'user-circle-o',
      title: 'GUARDIAN.SEARCH_TITLE',
      resource: 'guardian',
      permission: 'search',
      requiresTenant: true
    }
  },
  guardian: {
    path: 'guardians/:id',
    data: {
      icon: 'user-circle-o',
      title: 'GUARDIAN.DETAIL_TITLE',
      resource: 'guardian',
      permission: getCreateUpdatePermission,
      requiresTenant: true
    }
  },
  teachers: {
    path: 'teachers',
    sidebar: true,
    data: {
      icon: 'id-card-o',
      title: 'TEACHER.SEARCH_TITLE',
      resource: 'teacher',
      permission: 'search',
      requiresTenant: true
    }
  },
  teacher: {
    path: 'teachers/:id',
    data: {
      icon: 'id-card-o',
      title: 'TEACHER.DETAIL_TITLE',
      resource: 'teacher',
      permission: getCreateUpdatePermission,
      requiresTenant: true
    }
  },
  employees: {
    path: 'employees',
    sidebar: true,
    data: {
      icon: 'id-badge',
      title: 'EMPLOYEE.SEARCH_TITLE',
      resource: 'employee',
      permission: 'search',
      requiresTenant: true
    }
  },
  employee: {
    path: 'employees/:id',
    data: {
      icon: 'id-badge',
      title: 'EMPLOYEE.DETAIL_TITLE',
      resource: 'employee',
      permission: getCreateUpdatePermission,
      requiresTenant: true
    }
  },
  supervisors: {
    path: 'supervisors',
    sidebar: true,
    data: {
      icon: 'street-view',
      title: 'SUPERVISOR.SEARCH_TITLE',
      resource: 'supervisor',
      permission: 'search',
      requiresTenant: true
    }
  },
  supervisor: {
    path: 'supervisors/:id',
    data: {
      icon: 'street-view',
      title: 'SUPERVISOR.DETAIL_TITLE',
      resource: 'supervisor',
      permission: getCreateUpdatePermission,
      requiresTenant: true
    }
  },
  organization: {
    header: true,
    sidebar: true,
    data: {
      title: 'MENUASIDE.ORGANIZATION'
    }
  },
  divisions: {
    path: 'divisions',
    sidebar: true,
    data: {
      icon: 'th-large',
      title: 'DIVISION.SEARCH_TITLE',
      resource: 'division',
      permission: 'search',
      requiresTenant: true
    }
  },
  division: {
    path: 'divisions/:id',
    data: {
      icon: 'th-large',
      title: 'DIVISION.DETAIL_TITLE',
      resource: 'division',
      permission: getCreateUpdatePermission,
      requiresTenant: true
    }
  },
  reportCard: {
    path: 'report-cards/:id',
    data: {
      icon: 'table',
      title: 'REPORT_CARDS.DETAIL_TITLE',
      resource: 'report-card',
      permission: getCreateUpdatePermission,
      requiresTenant: true
    }
  },
  subjectReportCards: {
    path: 'divisions/:divisionId/courses/:courseId/reportCards',
    data: {
      icon: 'table',
      title: 'REPORT_CARDS.DETAIL_TITLE',
      resource: 'report-card',
      permission: getCreateUpdatePermission,
      requiresTenant: true
    }
  },
  grades: {
    path: 'grades',
    sidebar: true,
    data: {
      icon: 'sitemap',
      title: 'GRADE.SEARCH_TITLE',
      resource: 'stage',
      permission: 'search',
      requiresTenant: true
    }
  },
  grade: {
    path: 'grades/:id',
    data: {
      icon: 'sitemap',
      title: 'GRADE.DETAIL_TITLE',
      resource: 'stage',
      permission: getGradeCreateUpdatePermission,
      requiresTenant: true
    }
  },
  subjects: {
    path: 'subjects',
    sidebar: true,
    data: {
      icon: 'book',
      title: 'SUBJECT.SEARCH_TITLE',
      resource: 'subject',
      permission: 'search',
      requiresTenant: true
    }
  },
  subject: {
    path: 'subjects/:id',
    data: {
      icon: 'book',
      title: 'SUBJECT.DETAIL_TITLE',
      resource: 'subject',
      permission: getCreateUpdatePermission,
      requiresTenant: true
    }
  },
  // terms: {
  //   path: 'terms',
  //   sidebar: true,
  //   data: {
  //     icon: 'calendar-check-o',
  //     title: 'TERM.SEARCH_TITLE',
  //     resource: 'term',
  //     permission: 'search',
  //     requiresTenant: true
  //   }
  // },
  // term: {
  //   path: 'terms/:id',
  //   data: {
  //     icon: 'calendar-check-o',
  //     title: 'TERM.DETAIL_TITLE',
  //     resource: 'term',
  //     permission: getCreateUpdatePermission,
  //     requiresTenant: true
  //   }
  // },

  others: {
    header: true,
    sidebar: false,
    data: {
      title: 'MENUASIDE.OTHERS'
    }
  },
  batchJobs: {
    path: 'batch-jobs',
    sidebar: false,
    data: {
      icon: 'tasks',
      title: 'BATCH_JOB.SEARCH_TITLE',
      resource: 'batch-job',
      permission: 'search',
      requiresTenant: true
    }
  },
  batchJob: {
    path: 'batch-jobs/:id',
    data: {
      icon: 'tasks',
      title: 'BATCH_JOB.DETAIL_TITLE',
      resource: 'batch-job',
      permission: 'get',
      requiresTenant: true
    }
  },
  reports: {
    path: 'reports',
    sidebar: false,
    data: {
      icon: 'table',
      title: 'REPORTS.TITLE',
      resource: 'reports',
      permission: 'request',
      requiresTenant: true,
    }
  },
  invoices: {
    path: 'v2/invoices',
    sidebar: true,
    external: true,
    data: {
      icon: 'dollar',
      title: 'Facturación',
      resource: 'invoices',
      permission: 'search',
      requiresTenant: true,
      isInvoice: true
    }
  },
}

@Component( {
  selector: 'app-layouts-auth',
  templateUrl: './auth.html'
})
export class LayoutsAuthComponent implements OnInit, OnDestroy {
  public mylinks: Array<any> = []
  public pageData: any = {}
  private stageSubscription: any

  constructor (
    private confirmationService: ConfirmationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private termService: TermService,
    private authService: AuthService
  ) {
  }

  public ngOnInit () {
    //  sending the resize event, for AdminLTE to place the height
    let ie = this.detectIE()
    if ( !ie ) {
      window.dispatchEvent( new Event( 'resize' ) )
    } else {
      // solution for IE from @hakonamatata
      let event = document.createEvent( 'Event' )
      event.initEvent( 'resize', false, true )
      window.dispatchEvent( event )
    }

    // build the links array
    this.mylinks = []
    _.forEach(PAGES, (page: any) => {
      if (page.sidebar) {
        if (page.path) {
          page.link = [ '/' + page.path ]
        }
        this.mylinks.push(page)
      }
    })

    // based on https://toddmotto.com/dynamic-page-titles-angular-2-router-events
    this.router.events
    .filter(event => event instanceof NavigationEnd)
    .map(() => this.activatedRoute)
    .map(route => {
      while (route.firstChild) route = route.firstChild
      return route
    })
    .filter(route => route.outlet === 'primary')
    .mergeMap(route => route.data)
    .subscribe(event => {
      this.pageData = event
    })

    this.stageSubscription = this.authService.subscribeToStage((data: any) => {
      if (data && !data.initial) {
        this.router.navigate(['/'])
      }
    })

    const term = this.authService.getSelectedTerm()
    if (term) {
      this.termService.getSchoolStructure(term._id)
      .subscribe(
        // @ts-expect-error ts-migrate(6133) FIXME: 'result' is declared but its value is never read.
        result => {
          // console.log(result)
        },
        // @ts-expect-error ts-migrate(6133) FIXME: 'err' is declared but its value is never read.
        err => {
          // console.error(err)
        }
      )
    }
  }

  public onConfirmationModalInitialized (confirmationModal: ConfirmationModal) {
    this.confirmationService.initialize(confirmationModal)
  }

  protected detectIE (): any {
    let ua = window.navigator.userAgent

    // Test values; Uncomment to check result …
    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
    // IE 12 / Spartan
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
    // Edge (IE 12+)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko)
    // Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    let msie = ua.indexOf( 'MSIE ' )
    if ( msie > 0 ) {
      // IE 10 or older => return version number
      return parseInt( ua.substring( msie + 5, ua.indexOf( '.', msie ) ), 10 )
    }

    let trident = ua.indexOf( 'Trident/' )
    if ( trident > 0 ) {
      // IE 11 => return version number
      let rv = ua.indexOf( 'rv:' )
      return parseInt( ua.substring( rv + 3, ua.indexOf( '.', rv ) ), 10 )
    }

    let edge = ua.indexOf( 'Edge/' )
    if ( edge > 0 ) {
      // Edge (IE 12+) => return version number
      return parseInt( ua.substring( edge + 5, ua.indexOf( '.', edge ) ), 10 )
    }

    // other browser
    return false
  }

  ngOnDestroy () {
    this.stageSubscription.unsubscribe()
  }

}
