<!-- Left side column. contains the logo and sidebar -->
<aside class="main-sidebar">
  <!-- sidebar: style can be found in sidebar.less -->
  <section class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <!-- <tenant-popover></tenant-popover> -->
    <div class="tenant-menu" *ngIf="tenant">
      <a class="tenant-entry" (click)="goToCurrentTenant()">
        <div class="image">
          <avatar [name]="tenant?.name" [photo]="tenant?.logo?.secure_url" size="40"></avatar>
        </div>
        <div class="info">
          {{ tenant?.name }}
        </div>
      </a>
    </div>

    <!-- search form (Optional) -->
<!--     <form action="#" method="get" class="sidebar-form">
      <div class="input-group">
        <input type="text" name="q" class="form-control" placeholder="{{ 'MENUASIDE.PHR_SEARCH' | translate }}"> <span class="input-group-btn">
          <button type="submit" name="search" id="search-btn"
            class="btn btn-flat">
            <i class="fa fa-search"></i>
          </button>
        </span>
      </div>
    </form> -->
    <!-- /.search form -->
    <!-- Sidebar Menu -->
    <ul class="sidebar-menu">
      <!-- <li class="header">{{ 'MENUASIDE.LBL_NAVIGATION' | translate }}</li> -->
      <ng-template ngFor let-item [ngForOf]="links">

        <ng-template [ngIf]="item.header">
          <li class="header">{{ item.data.title | translate }}</li>
        </ng-template>

        <ng-template [ngIf]="!item.header && !item.sublinks && isVisible(item)">
          <li routerLinkActive="active">
            <a *ngIf="!item.external" [routerLink]="item.link">
              <i class="fa fa-fw fa-{{ item.data.icon }}"></i>
              <span>{{ item.data.title | translate }}</span>
            </a>
            <a *ngIf="item.external" [href]="item.link">
              <i class="fa fa-fw fa-{{ item.data.icon }}"></i>
              <span>{{ item.data.title | translate }}</span>
            </a>
          </li>
        </ng-template>

        <ng-template [ngIf]="!item.header && item.sublinks && isAllowed(item)">
          <li class="treeview">
            <a href="#">
              <i *ngIf="item.data.icon" class="fa fa-fw fa-{{ item.data.icon }}"></i>
              <span>{{ item.data.title | translate }}</span>
              <i class="fa fa-angle-left pull-right"></i>
            </a>
            <ul class="treeview-menu">
              <ng-template ngFor let-subitem [ngForOf]="item.sublinks">
                <li routerLinkActive="active">
                  <a *ngIf="!subitem.external" [routerLink]="subitem.link">
                    <i *ngIf="subitem.data.icon" class="fa fa-fw fa-{{ subitem.data.icon }}"></i>
                    <span>{{ subitem.data.title | translate }}</span>
                  </a>
                  <a *ngIf="subitem.external" [href]="subitem.link" [target]="subitem.target">
                    <i *ngIf="subitem.data.icon" class="fa fa-fw fa-{{ subitem.data.icon }}"></i>
                    <span>{{ subitem.data.title | translate }}</span>
                  </a>
                </li>
              </ng-template>
            </ul>
          </li>
        </ng-template>

      </ng-template>
    </ul>
    <!-- /.sidebar-menu -->
  </section>
  <!-- /.sidebar -->
</aside>

