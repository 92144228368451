

// @ts-expect-error ts-migrate(6133) FIXME: 'SimpleChanges' is declared but its value is never... Remove this comment to see the full error message
import { Component, Input, SimpleChanges } from '@angular/core'
import { ViewService, MeService } from '../../services'
import { TenantSummary } from '../../interfaces'

@Component({
  selector: 'app-menu-aside',
  templateUrl: './menu-aside.component.html'
})
export class MenuAsideComponent {
  @Input() links: Array<any> = []


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'tenant' has no initializer and is not de... Remove this comment to see the full error message
  public tenant: TenantSummary

  constructor (
    private meService: MeService,
    private viewService: ViewService
  ) {
    this.meService.subscribeTenantChange(this)
    this.onTenantChange()
  }

  public goToCurrentTenant () {
    return this.viewService.goTo([`tenants/${this.tenant._id}`])
  }

  public onTenantChange () {
    this.tenant = this.viewService.getTenant()
  }



  // @ts-expect-error ts-migrate(6133) FIXME: 'isVisible' is declared but its value is never rea... Remove this comment to see the full error message
  private isVisible (page: any) {
    if (!page.sidebar) {
      return false
    }
    const tenant = this.viewService.getTenant()
    if (!tenant && page.data.requiresTenant) {
      return false
    }
    if (page.data.isInvoice && !tenant.invoices_enabled) {
      return false
    }
    return this.isAllowed(page, tenant)
  }

  private isAllowed (page: any, tenant: any): boolean {
    const resource = typeof page.data.resource === 'function' ? page.data.resource(null, tenant) : page.data.resource
    const permission = typeof page.data.permission === 'function' ? page.data.permission(null, tenant) : page.data.permission
    return !page.data.resource || !page.data.permission || this.viewService.isAllowed(resource, permission)
  }
}
